import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { getACDownload } from "../../api/anticheat";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import ACCoin from "../custom/ACCoin";
import CustomModal from "../custom/CustomModal";
import SecondaryButton from "../custom/SecondaryButton";
import download from "downloadjs";
import { convertToROT7 } from "../../utils/helpers";

const DownloadACModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setHasDownloaded(false);
    setLoading(false);
    onClose();
  };

  const handleDownload = () => {
    setLoading(true);
    getACDownload(middleware).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        // Simplified - just download the zip as is
        download(
          new Blob([res], { type: "application/octet-stream" }),
          'RivoxAC.zip',
          "application/octet-stream"
        );
      }
    });
  };

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 400,
      textAlign: "center",
      maxWidth: 500,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={"Rivox Anti-Cheat"}
      description="You must be running Rivox AC before continuing"
      titleSize="large"
      icon={<ACCoin size={40} />}
      bottomContent={
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <SecondaryButton
              fullWidth
              label="Download Rivox AC"
              bg={doubtedBlue}
              icon={<ACCoin size={24} />}
              loading={loading}
              onClick={handleDownload}
            />
            <SecondaryButton
              fullWidth
              label="Nevermind"
              onClick={handleClose}
            />

            <Grid item sx={{ marginTop: 1 }}>
              <Typography sx={styles.meta}>
                Attempting to reverse-engineer or crack{" "}
                <span style={{ fontWeight: 800, color: doubtedBlue }}>
                  Rivox AC
                </span>{" "}
                may cause punishable offenses on the Rivox Service
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.label}>How it works</Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ ...styles.value, maxWidth: 500 }}>
                <span style={{ fontWeight: 800, color: doubtedBlue }}>
                  Rivox AC
                </span>{" "}
                is an anti-cheat developed by the Rivox Team and scans your device
                for potentially malicious cheat softwares or processes.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.label}>
                If you have already downloaded{" "}
                <span style={{ fontWeight: 800 }}>Rivox AC</span>{" "}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ ...styles.value, maxWidth: 500 }}>
                <li>
                  Make sure you are running{" "}
                  <span style={{ fontWeight: 800, color: doubtedBlue }}>
                    Rivox AC
                  </span>{" "}
                  before joining or creating a match.
                </li>
                <li>
                  If you close{" "}
                  <span style={{ fontWeight: 800, color: doubtedBlue }}>
                    Rivox AC
                  </span>{" "}
                  while playing a match, Rivox will detect this and you will
                  forfeit the match.
                </li>
                <li>
                  Make sure you do not rename the{" "}
                  <span style={{ fontWeight: 800, color: doubtedBlue }}>
                    Rivox AC
                  </span>{" "}
                  file once downloaded.
                </li>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default DownloadACModal;
